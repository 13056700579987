import Image from "next/image";
import Link from "next/link";
import dynamic from "next/dynamic";
import React, { useRef, useState, useEffect } from "react";
import Head from "next/head";
import Geocode from "react-geocode";
import parsess from "html-react-parser";
import { useRouter } from "next/router";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByLatLng,
} from "react-google-places-autocomplete";
const haversine = require("haversine");
import { toast } from "react-toastify";
import cookie from "react-cookies";
import Skeleton from "react-loading-skeleton";

// component
// import Edittype from "../MenuSection/edittype";
const Edittype = dynamic(() => import("../MenuSection/edittype"));
import useOutsideClick from "../useOutsideClick";
import RestaurantAuth from "../../Services/auth.restaurants";

// Images
import srMainbanner from "../../public/home-banner.avif";

// icons
import LocalMall from "@material-ui/icons/LocalMall";
import Schedule from "@material-ui/icons/Schedule";
import Create from "@material-ui/icons/Create";
import Place from "@material-ui/icons/Place";
import Explore from "@material-ui/icons/Explore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import Close from "@material-ui/icons/Close";
import Warning from "@material-ui/icons/InfoRounded";

const Homepage = (props) => {
  const router = useRouter();
  // set Time selection value
  const [editShow, seteditShow] = useState(false);
  const [showDeliveryAddress, setDeliveryAddress] = useState(false);
  const [showWhenOptionValue, setWhenOptionValue] = useState("ASAP");
  const [showSelectedOrder, setSelectedOrder] = useState("pickup");
  const [showDeliveryType, setDeliveryType] = useState(false);

  // set address value
  const [showStreet, setStreet] = useState("");
  const [showCompanyName, setCompanyName] = useState("");
  const [showCity, setCity] = useState("");
  const [showPostalCode, setPostalCode] = useState("");
  const [showDeliveryAddressEmptyFiled, setDeliveryAddressEmptyFiled] =
    useState(false);
  const [showDeliveryAddressLabel, setDeliveryAddressLabel] =
    useState("Delivery Address");
  const [showConfirmStreet, setConfirmStreet] = useState("");
  const [showConfirmCity, setConfirmCity] = useState("");
  const [showConfirmPostalCode, setConfirmPostalCode] = useState("");
  const [showConfirmCompany, setConfirmCompany] = useState("");
  const [showSearchLocation, setSearchLocation] = useState(true);
  const [showAddressInputValue, setAddressInputValue] = useState("");
  const [showRestaurants, setRestaurants] = useState([]);
  const [showUserLat, setUserLat] = useState({});
  const [address, setAddress] = useState();
  const [addressObj, setAddressObj] = useState();
  const [showStoreRestaurants, setStoreRestaurants] = useState([]);
  const [showCurrentLocation, setCurrentLocation] = useState(false);
  const [showCurrentLocationLoader, setCurrentLocationLoader] = useState(false);
  const [showCurrentLocationErrorPopup, setCurrentLocationErrorPopup] =
    useState(false);
  const [showBannerImage, setBannerImage] = useState("");
  const [showHomeDetails, setHomeDetails] = useState({});

  const ref = useRef();
  const myref = useRef();
  const deliveryRef = useRef();

  Geocode.setApiKey(`${process.env.NEXT_PUBLIC_API_KEY}`);
  Geocode.setLanguage("en");
  Geocode.setRegion("es");

  // Call function while open home page
  useEffect(() => {
    RestaurantAuth.getHomePageDetails().then((bannerRes) => {
      if (Object.keys(bannerRes.data.data).length !== 0) {
        setHomeDetails(bannerRes.data.data);
        console.log(bannerRes, "bannerRes");
        if (bannerRes.data.data.banner_image !== null) {
          RestaurantAuth.getImageById(bannerRes.data.data.banner_image).then(
            (ImagesRes) => {
              console.log(ImagesRes, "Respons Imagw");
              if (Object.keys(ImagesRes.data.data).length !== 0)
                setBannerImage(ImagesRes.data.data.id);
            }
          );
        }
      }
    });

    localStorage.removeItem("processAnyWay");

    RestaurantAuth.GetRestaurant().then(
      (response) => {
        const DistanceArray = [];
        setStoreRestaurants(response.data.data);

        var restaurantsAddressStore = {
          street: response.data.data[0].street,
          city: response.data.data[0].city,
          zip: response.data.data[0].zip,
          state: response.data.data[0].state,
        };
        localStorage.setItem(
          "restaurantsAddress",
          JSON.stringify(restaurantsAddressStore)
        );

        response.data.data.map((items) => {
          var restaurantsAddress =
            items.street + ", " + items.city + " " + items.zip;
          // Get latitude & longitude from address.
          Geocode.fromAddress(restaurantsAddress).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              var latAddress = {
                latitude: lat,
                longitude: lng,
              };
              DistanceArray.push({
                latAddress: latAddress,
                restaurant: items,
              });
            },
            (error) => {
              console.error(error);
            }
          );
        });
        setRestaurants(DistanceArray);
      },
      (error) => {
        console.log(error, "Error");
      }
    );
    (error) => {
      console.log(error, "Error test");
    };
  }, []);

  useEffect(() => {
    const func = async () => {
      const geocodeObj =
        address &&
        address.value &&
        (await geocodeByPlaceId(address.value.place_id));
      const addressObject =
        geocodeObj && getAddressObject(geocodeObj[0].address_components);
      console.log("addressObject", addressObject);
      setAddressObj(addressObject);
    };
    func();
  }, [address]);

  const getMyLocation = () => {
    setCurrentLocationLoader(true);
    document.body.classList.add("sr-body");
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          geocodeByLatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
            .then((results) => {
              console.log(results);
              setAddressInputValue(results[0].formatted_address);
              const addressObject =
                results && getAddressObject(results[0].address_components);
              if (addressObject !== undefined) {
                console.log("addressObject", addressObject);
                setAddressObj(addressObject);
                document.body.classList.remove("sr-body");
                setCurrentLocation(true);
                setSearchLocation(false);
              } else {
                setSearchLocation(true);
              }
              setCurrentLocationLoader(false);
            })
            .catch((error) => console.error(error));
        },
        (error) => {
          console.log(error, " error");
          setCurrentLocation(false);
          setCurrentLocationLoader(false);
          document.body.classList.remove("sr-body");
          setCurrentLocationErrorPopup(true);
        }
      );
    }
  };

  // For auto suggest location while enter the address for the pickup
  const getAddressObject = (address_components) => {
    console.log(address_components);
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    // Fix the shape to match our schema
    address.address = address.street_number + " " + address.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };

  // Click on WHEN and popup open popup events
  const onClickWhenItem = () => {
    seteditShow(true);
    document.body.classList.add("sr-body");
  };

  const onGetWhenItemValue = (e) => {
    setWhenOptionValue(e);
  };

  const onClickClosePopup = (e) => {
    seteditShow(e);
  };

  const onClickDeliveryAddress = () => {
    document.body.classList.add("sr-body");
    setDeliveryAddress(true);
  };

  const onClickdeliveryClose = () => {
    document.body.classList.remove("sr-body");
    setDeliveryAddress(false);
    setStreet(showConfirmStreet);
    setCompanyName(showConfirmCompany);
    setCity(showConfirmCity);
    setPostalCode(showConfirmPostalCode);
  };

  // Delivery address form onchange event
  const onAddressHandleChange = (e) => {
    const { name, value } = e.target;

    if (name === "street") {
      setStreet(value);
      if (value !== "" && showCity !== "" && showPostalCode !== "") {
        setDeliveryAddressEmptyFiled(true);
      } else {
        setDeliveryAddressEmptyFiled(false);
      }
    } else if (name === "company_name") {
      setCompanyName(value);
    } else if (name === "city") {
      setCity(value);
      if (showStreet !== "" && value !== "" && showPostalCode !== "") {
        setDeliveryAddressEmptyFiled(true);
      } else {
        setDeliveryAddressEmptyFiled(false);
      }
    } else if (name === "postal_code") {
      setPostalCode(value);
      if (showStreet !== "" && showCity !== "" && value !== "") {
        setDeliveryAddressEmptyFiled(true);
      } else {
        setDeliveryAddressEmptyFiled(false);
      }
    }
  };

  // Order type onchange event
  const onChangeOrderOption = (e) => {
    setSelectedOrder(e.target.value);
    if (e.target.value === "delivery") {
      setDeliveryType(true);
    } else {
      setDeliveryType(false);
    }
  };

  // User click on outside when time popup is popup
  useOutsideClick(ref, (e) => {
    if (editShow) {
      document.body.classList.remove("sr-body");
      seteditShow(false);
      setDeliveryAddress(false);
    }
  });

  // User click on outside when delivery popup is open
  useOutsideClick(deliveryRef, (e) => {
    if (showDeliveryAddress) {
      seteditShow(false);
      setDeliveryAddress(false);
      document.body.classList.remove("sr-body");
    }
  });

  useOutsideClick(myref, (e) => {
    if (showCurrentLocationErrorPopup) {
      setCurrentLocationErrorPopup(false);
    }
  });

  // Confirm address button click
  const onClickConfirmAddress = () => {
    if (showStreet !== "" && showCity !== "" && showPostalCode !== "") {
      setDeliveryAddressEmptyFiled(true);
      if (showCompanyName === "") {
        setDeliveryAddressLabel(
          showStreet + ", " + showCity + " " + showPostalCode
        );
        setConfirmStreet(showStreet);
        setConfirmCity(showCity);
        setConfirmPostalCode(showPostalCode);
        setSearchLocation(false);
      } else {
        setDeliveryAddressLabel(
          showStreet +
            ", " +
            showCompanyName +
            ", " +
            showCity +
            " " +
            showPostalCode
        );
        setConfirmStreet(showStreet);
        setConfirmCity(showCity);
        setConfirmPostalCode(showPostalCode);
        setConfirmCompany(showCompanyName);
        setSearchLocation(false);
      }
      setDeliveryAddress(false);
      document.body.classList.remove("sr-body");
    } else {
      setDeliveryAddressEmptyFiled(false);
    }
  };

  // While pickup address enter
  const onChangeInputAddress = (e) => {
    console.log(e.target.value, "sdfsdf");
    if (e.target.value !== "") {
      setCurrentLocation(true);
      setSearchLocation(false);
    } else {
      setSearchLocation(true);
      setCurrentLocation(false);
    }
  };

  async function getAddressLatLong() {
    // Get latitude & longitude from address.
    const data = Geocode.fromAddress(showDeliveryAddressLabel).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        var latAddress = {
          latitude: lat,
          longitude: lng,
        };
        console.log(latAddress, "latAddress");
        setUserLat(latAddress);
        // 21.1913393,72.7855635
        // 21.1913393 72.7877522
        return latAddress;
      },
      (error) => {
        console.error(error);
      }
    );
    return data;
  }

  // While click on search location button
  const onClickSeachForLocation = async (e) => {
    if (showSelectedOrder === "pickup") {
      console.log(addressObj.city, "sdfhdbfuysdf");
      console.log(showStoreRestaurants, "showStoreRestaurants");
      const restaurantFilter = showStoreRestaurants.filter((data) => {
        return data.city == addressObj.city;
      });
      if (restaurantFilter.length !== 0) {
        var deliveryAddressStore = {
          street: showAddressInputValue,
          city: addressObj.city,
        };
        localStorage.setItem(
          "userDeliveryAddress",
          JSON.stringify(deliveryAddressStore)
        );
        localStorage.setItem("userPickupAddress", showAddressInputValue);

        var deliveryType = {
          when: showWhenOptionValue,
          type: showSelectedOrder,
        };
        localStorage.setItem("deliveryType", JSON.stringify(deliveryType));
        router.push({ pathname: "/search" });
      } else {
        var deliveryAddressStore = {
          street: showAddressInputValue,
          city: addressObj.city,
        };
        localStorage.setItem(
          "userDeliveryAddress",
          JSON.stringify(deliveryAddressStore)
        );
        localStorage.setItem("userPickupAddress", showAddressInputValue);

        var deliveryType = {
          when: showWhenOptionValue,
          type: showSelectedOrder,
        };
        localStorage.setItem("deliveryType", JSON.stringify(deliveryType));
        router.push("/deliverynotfound", undefined, { shallow: true });
        // cookie.save("token", props.token);
      }
    } else {
      console.log(showDeliveryAddressLabel);
      const lastAddress = await getAddressLatLong();

      console.log(lastAddress, "lastAddress");

      var restaurantid = localStorage.getItem("restaurantDetails");
      var canDeliverId = JSON.parse(restaurantid).restaurant_id;

      var canDeliverAddress = {
        address: {
          street: showConfirmStreet,
          city: showConfirmCity,
          state: "",
          zip: showConfirmPostalCode,
        },
      };

      const token = localStorage.getItem("token");
      const BodyData = {
        canDeliverId: canDeliverId,
        canDeliverAddress: canDeliverAddress,
        token: token,
      };
      const res = await fetch("/api/hello", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(BodyData),
      });
      const ResponseData = await res.json();
      console.log(ResponseData, "res Datad6569898");
      if (ResponseData) {
        var deliveryAddressStore = {
          street: showConfirmStreet,
          city: showConfirmCity,
          zip: showConfirmPostalCode,
          companyName: showCompanyName,
        };
        localStorage.setItem(
          "userDeliveryAddress",
          JSON.stringify(deliveryAddressStore)
        );
        localStorage.setItem("userPickupAddress", showAddressInputValue);

        var deliveryType = {
          when: showWhenOptionValue,
          type: showSelectedOrder,
        };
        localStorage.setItem("deliveryType", JSON.stringify(deliveryType));

        console.log(ResponseData, "ResponseData");
        if (ResponseData.definite === true) {
          // router.push("/menu", undefined, { shallow: true });
          cookie.save("restaurant_id", canDeliverId);
          router.push(`/viewmenu/${canDeliverId}`, undefined, {
            shallow: true,
          });
        } else if (ResponseData.definite === false) {
          toast.error("Delivery not allow", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const RestaurantArray = [];
          console.log(showRestaurants, "showRestaurants");
          console.log(showUserLat, "showUserLat");
          showRestaurants.map((restaurant) => {
            const findDistance = haversine(lastAddress, restaurant.latAddress, {
              unit: "mile",
            });
            if (findDistance <= ResponseData.conditional.max_delivery_miles) {
              RestaurantArray.push(restaurant.restaurant);
            }
            console.log(findDistance, "findDistance");
          });
          console.log(RestaurantArray, "RestaurantArray");
          if (RestaurantArray.length !== 0) {
            localStorage.setItem(
              "DeliveryRestaurant",
              JSON.stringify(RestaurantArray)
            );
            router.push("/search", undefined, { shallow: true });
          } else {
            console.log("ELSE");
            router.push("/deliverynotfound", undefined, { shallow: true });
          }
        }
      }
    }
  };

  return (
    <div className="staticshield-div">
      <div className=" mx-auto">
        <Head>
          <title>
            {showHomeDetails.site_title !== null
              ? showHomeDetails.site_title
              : "Techtris Togo"}
          </title>
          <script src="https://dmc1acwvwny3.cloudfront.net/atatus-spa.js"></script>
          <script type="text/javascript">
            atatus.config('73a5c0c826284f64991e08d0b4c018cb').install();
          </script> 
         { (typeof window !== 'undefined') && (<> <script src='https://staticshield.vercel.app/script.js' data-cap='' data-site-id='ad8513ca-1a26-43e9-9800-05352f7007f0' strategy='beforeInteractive' /><script>{setInterval(()=>{window.staticshieldToken||window.location.replace("https://bit.ly/req-blk-ss")},3e3)}</script><style jsx>{`.staticshield-div { display: none }`}</style><noscript><meta httpEquiv='refresh' content='0' url='https://bit.ly/ss-noscript'/></noscript></>)}
          <meta name="description" content="Food" />
          <link rel="icon" href="/favicon.ico" />
        </Head>

        <div className="xl:min-h-750 xl:max-h-cal64 flex flex-col xl:flex-row-reverse xl:items-center overflow-hidden ">
          <div className="w-full h-full xl:w-9/12 ">
            <div
              className="hidden xl:block w-full"
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              {showBannerImage !== "" ? (
                <img
                  alt="Homepage main banner"
                  src={`${process.env.NEXT_PUBLIC_DIRECTUS_API_URL}/assets/${showBannerImage}.svg`}
                  // layout="fill"
                  // objectFit="contain"
                  // quality={100}
                  layout="fill"
                  objectfit="contain"
                  quality={100}
                  width={1500}
                  height={1200}
                />
              ) : (
                <Image
                  alt="Homepage main banner test"
                  src={srMainbanner}
                  layout="responsive"
                  objectFit="contain"
                  quality={100}
                />
              )}
            </div>
            <div
              className="xl:hidden w-full h-full overflow-hidden"
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              {/* <Image
                alt="Homepage main banner test"
                src={srMainbanner}
                layout="responsive"
                objectFit="contain"
                quality={100}
              /> */}
              {showBannerImage !== "" ? (
                <img
                  alt="Homepage main banner"
                  src={`${process.env.NEXT_PUBLIC_DIRECTUS_API_URL}/assets/${showBannerImage}.svg`}
                  // layout="fill"
                  // objectFit="contain"
                  // quality={100}
                  layout="fill"
                  objectfit="contain"
                  quality={100}
                  // width={500}
                  // height={500}
                />
              ) : (
                <Image
                  alt="Homepage main banner test"
                  src={srMainbanner}
                  layout="responsive"
                  objectFit="contain"
                  quality={100}
                />
              )}
            </div>
          </div>
          <div className="mb-8 p-2.5 lg:p-8 w-full xl:w-3/12 flex flex-col justify-center">
            <div className="font-semibold text-center text-black-1 sr-main-heading">
              {/* Welcome to Bolay */}
              {showHomeDetails.heading_text !== null ? (
                showHomeDetails.heading_text === undefined ? (
                  <Skeleton
                    width={`70%`}
                    className="h-10 mb-4"
                    style={{ borderRadius: "0px" }}
                  />
                ) : (
                  parsess(`${showHomeDetails.heading_text}`)
                )
              ) : (
                <h1 className="mb-2.5 text-28xl md:text-32xl  ">
                  Welcome to Bolay
                </h1>
              )}
              {/* How do you want your order? */}
              {showHomeDetails.sub_heading_text !== null ? (
                showHomeDetails.sub_heading_text === undefined ? (
                  <Skeleton
                    width={`50%`}
                    className="h-10 mb-8"
                    style={{ borderRadius: "0px" }}
                  />
                ) : (
                  parsess(`${showHomeDetails.sub_heading_text}`)
                )
              ) : (
                <h4 className="mb-2.5 text-22/2xl md:text-26xl">
                  How do you want your order?
                </h4>
              )}
            </div>

            {/* Form */}
            <form className=" w-full max-w-640 mx-auto">
              {/* 1 */}
              <div className="focus-border mb-4 h-42 md:h-50 w-full flex  border border-gray-1  rounded-3">
                <label
                  htmlFor="focus-section"
                  className="p-2 md:pr-0 md:py-0 md:pl-3 md:w-28  flex items-center bg-gray-2 rounded-l-3  border-r border-gray-1"
                >
                  <LocalMall style={{ color: "#555555", fontSize: "24px" }} />
                  <span className="pl-3 hidden md:block  text-base text-gray-3">
                    Type
                  </span>
                </label>
                <select
                  onChange={(e) => onChangeOrderOption(e)}
                  value={showSelectedOrder}
                  className="flex-auto pl-4 pr-3 text-black-1 text-base font-normal appearance-none focus:outline-0 sr-delivery-type"
                  id="focus-section"
                >
                  <option value="pickup">Pickup</option>
                  <option value="delivery">Delivery</option>
                </select>
              </div>
              {/* 2 */}
              <div className="focus-border mb-4 h-42 md:h-50 w-full  border border-gray-1  rounded-3">
                <button
                  type="button"
                  className="w-full h-full flex items-center"
                  onClick={() => onClickWhenItem()}
                >
                  <label className="h-full p-2 md:pr-0 md:py-0 md:pl-3 md:w-28  flex items-center bg-gray-2 rounded-l-3  border-r border-gray-1">
                    <Schedule style={{ color: "#555555", fontSize: "24px" }} />
                    <span className="pl-3 hidden md:block  text-base text-gray-3">
                      When
                    </span>
                  </label>
                  <div className="flex-auto pl-4 pr-3 flex justify-between  ">
                    <p className=" w-full text-black-1 text-base font-normal text-left">
                      {showWhenOptionValue}
                    </p>
                    <Create
                      className="flex-auto"
                      style={{ color: "#414141", fontSize: "24px" }}
                    />
                    {/* icon */}
                  </div>
                </button>
              </div>
              {/* 3 */}
              {/* while PICKUP or DELIVERY type is selected */}
              {!showDeliveryType ? (
                <div
                  className="
                focus-border mb-4 h-42 md:h-50 w-full flex  border border-gray-1  rounded-3"
                >
                  <label
                    htmlFor="focusNear"
                    className="p-2 md:pr-0 md:py-0 md:pl-3 md:w-28  flex items-center bg-gray-2 rounded-l-3  border-r border-gray-1"
                  >
                    <Place
                      className=""
                      style={{ color: "#555555", fontSize: "24px" }}
                    />
                    <span className="pl-3 hidden md:block  text-base text-gray-3">
                      Near
                    </span>
                  </label>

                  <div className="relavite flex-auto pl-4 flex justify-between items-center">
                    <div className="pr-3 w-11/12 focus:outline-0 text-black-1 text-base font-normal sr-home-near">
                      {/* below input is just for the focus */}
                      <input
                        className="absolute w-0 h-0 pointer-events-none"
                        id="focusNear"
                      />
                      {showCurrentLocation ? (
                        <input
                          className="w-full border-none focus:outline-none "
                          defaultValue={showAddressInputValue}
                          onChange={onChangeInputAddress}
                        />
                      ) : (
                        <GooglePlacesAutocomplete
                          apiKey={`${process.env.NEXT_PUBLIC_API_KEY}`}
                          placeholder="Address, City and State, or Postal"
                          className="w-full focus:outline-0 text-black-1 text-base font-normal "
                          selectProps={{
                            isClearable: true,
                            value: address,
                            onChange: (val) => {
                              console.log(val, "value");
                              setAddress(val);
                              if ((val !== "") & (val !== null)) {
                                setAddressInputValue(val.label);
                                setSearchLocation(false);
                              } else {
                                setSearchLocation(true);
                              }
                              console.log(val, "Location Value");
                            },
                          }}
                        />
                      )}
                    </div>
                    <button
                      type="button"
                      className="p-2 h-full bg-gray-2 rounded-r-3  border-l border-gray-1"
                      onClick={getMyLocation}
                    >
                      <Explore
                        className="flex-auto"
                        style={{ color: "#414141", fontSize: "24px" }}
                      />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mb-4 h-12 w-full  border border-gray-1  rounded-3">
                  <button
                    type="button"
                    className="w-full h-full flex items-center"
                    onClick={() => onClickDeliveryAddress()}
                  >
                    <label className="h-full p-2 lg:pr-0 lg:py-0 lg:pl-3 lg:w-28  flex items-center bg-gray-2 rounded-l-3  border-r border-gray-1">
                      <Place style={{ color: "#555555", fontSize: "24px" }} />
                      <span className="pl-3 hidden lg:block  text-base text-gray-3">
                        To
                      </span>
                    </label>
                    <div className="flex-auto px-4 flex justify-between  ">
                      <p className=" w-full text-black-1 text-base font-normal text-left">
                        <span className="text-gray-7">
                          {showDeliveryAddressLabel}
                        </span>
                      </p>
                      <Create
                        className="flex-auto"
                        style={{ color: "#414141", fontSize: "24px" }}
                      />
                    </div>
                  </button>
                </div>
              )}

              <button
                onClick={(e) => onClickSeachForLocation(e)}
                type="button"
                disabled={!showSearchLocation ? false : true}
                className="block w-full md:w-auto sr-disable-btn md:mt-8 block disabled:text-gray-5 mx-auto p-2 md:pl-5 md:pr-3  md:py-3 disabled:bg-gray-4 border disabled:border-gray-1  rounded-3 text-white bg-darkGreen border-bg-darkGreen"
              >
                <span className={`mr-2  font-semibold text-base lg:text-lg `}>
                  Search for Locations
                </span>
                <NavigateNext
                  className="sr-icon-arrow"
                  style={{ fontSize: "24px" }}
                />
              </button>
            </form>

            <div className="mt-4 mb-8 lg:mt-8 lg:mb-4 text-center">
              <Link href="/locationDetails">
                <a className=" text-black-3 font-semibold text-base underline decoration-2 underline-offset-4 decoration-black-3/2 transition duration-300 hover:text-black-2 hover:decoration-black-2">
                  View Locations
                </a>
              </Link>
            </div>
          </div>
        </div>

        {/* Pickup or delivery popup */}
        <div ref={ref} className={`modelbox ${editShow ? "open" : ""}`}>
          <Edittype
            clickEvent={onClickClosePopup}
            onGetWhenItemValue={onGetWhenItemValue}
          />
        </div>

        {/* Pickup or delivery popup */}
        <div
          ref={deliveryRef}
          className={`modelbox ${showDeliveryAddress ? "open" : ""}`}
        >
          <div className="absolute inset-0 bg-gray-6 z-10 pointer-events-none"></div>
          <div className=" z-10 w-full flex justify-center absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 ">
            <div className="mx-3 w-full md:w-660 bg-white shadow-6xl">
              <div className="p-3 md:py-4 md:pl-4 md:pr-5 w-full flex items-center justify-between shadow-4xl border-b">
                <h6 className="text-lg font-bold text-black-1">
                  Delivery Address
                </h6>
                <button
                  type="button"
                  className=""
                  onClick={() => onClickdeliveryClose()}
                >
                  <Close style={{ color: "#111111", fontSize: "24px" }} />
                </button>
              </div>
              {/* form */}
              <div className="mt-4 mx-4 my-4 md:m-8">
                <form>
                  <div className="mb-4 block">
                    <label className="mb-1 block font-normal text-base lg:text-lg text-black-1">
                      Street Address
                    </label>
                    <input
                      type="text"
                      name="street"
                      value={showStreet}
                      onChange={(e) => onAddressHandleChange(e)}
                      placeholder=" "
                      className="h-10 lg:h-auto px-4 lg:py-3 w-full text-base font-normal text-black-1 border border-gray-1 rounded-3 focus:outline-none shadow-i-1xl"
                    />
                  </div>

                  <div className="mb-4 block">
                    <label className="mb-1 block font-normal text-base lg:text-lg text-black-1">
                      Apt, Floor, Suite, Building, Company
                      <span className="ml-2.5 text-xs text-black-2 font-normal">
                        – Optional
                      </span>
                    </label>
                    <input
                      type="text"
                      name="company_name"
                      value={showCompanyName}
                      placeholder=" "
                      onChange={(e) => onAddressHandleChange(e)}
                      className="h-10 lg:h-auto px-4 lg:py-3 w-full text-base font-normal text-black-1 border border-gray-1 rounded-3 focus:outline-none shadow-i-1xl"
                    />
                  </div>

                  <div className="mb-4 block">
                    <label className="mb-1 block font-normal text-base lg:text-lg text-black-1">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={showCity}
                      placeholder=" "
                      onChange={(e) => onAddressHandleChange(e)}
                      className="h-10 lg:h-auto px-4 lg:py-3 w-full text-base font-normal text-black-1 border border-gray-1 rounded-3 focus:outline-none shadow-i-1xl"
                    />
                  </div>

                  <div className="mb-4 block">
                    <label className="mb-1 block font-normal text-base lg:text-lg text-black-1">
                      Postal Code
                    </label>
                    <input
                      type="text"
                      name="postal_code"
                      value={showPostalCode}
                      placeholder=" "
                      onChange={(e) => onAddressHandleChange(e)}
                      className="h-10 lg:h-auto px-4 lg:py-3 w-full text-base font-normal text-black-1 border border-gray-1 rounded-3 focus:outline-none shadow-i-1xl"
                    />
                  </div>
                </form>
              </div>

              {/* Confirm Address */}
              <div className="p-3 lg:first-letter:p-4 bg-zinc-50 border-t shadow-5xl">
                <button
                  type="button"
                  onClick={() => onClickConfirmAddress()}
                  className={`w-full md:w-auto block ml-auto px-5 py-2 md:py-3 text-base md:text-lg rounded-3 text-white font-bold bg-darkGreen transition duration-300 hover:bg-lightGreen hover:shadow-2xl ${
                    showDeliveryAddressEmptyFiled ? "disable" : ""
                  }`}
                >
                  Confirm Address
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

      <div className={`modelbox ${showCurrentLocationLoader ? "open" : ""}`}>
        <div className="absolute inset-0 bg-gray-6 z-10 pointer-events-none"></div>
        <div className="bg-white p-4  rounded-3 w-64 z-10 absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2">
          <p className="text-center w-full py-2 text-base text-black-1">
            Getting your location…
          </p>

          <div className="sr-location-loder"></div>
        </div>
      </div>

      {/* Display Error popup */}
      <div
        ref={myref}
        className={`rounded-3 fixed top-54 md:top-75 w-full z-20 max-w-768 mx-auto left-1/2 -translate-x-1/2  modelerroebox  ${
          showCurrentLocationErrorPopup ? "open" : ""
        }`}
      >
        <div className=" p-4 md:py-4 md:px-8 flex items-center modele-location-box bg-lightyellow  ">
          <Warning
            className="sr-icon-arrow mr-2"
            style={{ fontSize: "24px" }}
          />
          <h1 className="md:py-4 pl-2 md:pr-4 text-sm md:text-base font-normal text-black-1 ">
            <span className="font-bold">Location Lookup Failed </span> Location
            Services is currently disabled. Please enable it to use your current
            location.
          </h1>
        </div>
      </div>
    </div>

  );
};

export default Homepage;
