import axios from "axios";
// import authHeader from "./auth.header";
// import * as Sentry from "@sentry/nextjs";

// const API_URL = "http://localhost:1337/api";
//
const API_URL = "https://techtris-1.herokuapp.com/api";

const DirectUs_API_URL = process.env.NEXT_PUBLIC_DIRECTUS_API_URL;

const GetRestaurant = () => {
  return axios.get(
    DirectUs_API_URL + "/items/restaurants?filter[status][_eq]=true"
  );
};

const getHomePageDetails = () => {
  return axios.get(DirectUs_API_URL + `/items/home_page_details`);
};

const getImageBy = () => {
  return axios.get(DirectUs_API_URL + `/files`);
};

const getImageById = (ImageID) => {
  return axios.get(DirectUs_API_URL + `/files/${ImageID}`);
};

const GetFavoriteAddress = () => {
  return axios.get(DirectUs_API_URL + `/items/favorite_address`);
};

const AddFavoriteAddress = (restaurantData) => {
  return axios.post(
    DirectUs_API_URL + `/items/favorite_address`,
    restaurantData
  );
};

const DeleteFavoriteAddress = (Address_id) => {
  return axios.delete(
    DirectUs_API_URL + `/items/favorite_address/${Address_id}`
  );
};


const GetUpsellGroup = () => {
  return axios.get(DirectUs_API_URL + `/items/upsell_groups`);
};

const getUpsellMenu = (menu_id) => {
  return axios.post(DirectUs_API_URL + "/get_upsell_menu_items/get_menu_items",menu_id);
};

const getAllMenus = (restaurant_Id) => {
  return axios.post(DirectUs_API_URL + "/get_all_menu/get_menus",restaurant_Id);
};


const FindRestaurant = () => {
  return axios.get(
    API_URL +
      "/restaurants?populate[0]=Restaurant_Details&Tag_line&populate[1]=MenuImageDetails.CategoryImages.DesktopImage&populate[2]=MenuImageDetails.CategoryImages.MobileTabletImage"
  );
};

const CreateRestaurant = (restaurantData) => {
  return axios.post(API_URL + "/restaurants", restaurantData);
};

const UpdateRestaurant = (restaurant_id, restaurantUpdateData) => {
  return axios.put(
    API_URL + `/restaurants/${restaurant_id}`,
    restaurantUpdateData
  );
};

const getUserDetails = (user_id) => {
  return axios.get(API_URL + `/users/${user_id}`);
};

const UpdateUserDetails = (user_id, UserData) => {
  return axios.put(API_URL + `/users/${user_id}`, UserData);
};

const AddFavoriteRestaurant = (restaurantData) => {
  return axios.post(API_URL + `/favorite-restaurants`, restaurantData);
};

const GetFavoriteRestaurant = () => {
  return axios.get(API_URL + `/favorite-restaurants?populate=*`);
};

const UpdateFavoriteRestaurant = (id, restaurantData) => {
  return axios.put(API_URL + `/favorite-restaurants/${id}`, restaurantData);
};

const userSignIn = (userData) => {
  return axios.post(API_URL + `/auth/local`, userData);
};

const menuItemCreate = (BodyData) => {
  return axios.post(API_URL + `/menu-items`, BodyData);
};

const menuItemFind = () => {
  // return axios.get(API_URL + `/menu-items?populate=*`)
  return axios.get(API_URL + `/menu-items?pagination[limit]=5000`);
};

const menuItemUpdate = (menu_id, BodyData) => {
  return axios.put(API_URL + `/menu-items/${menu_id}`, BodyData);
};

const bannerImageFind = () => {
  return axios.get(API_URL + `/home-page-banners?populate=*`);
};

export default {
  GetRestaurant,
  getHomePageDetails,
  getImageById,
  getImageBy,
  GetFavoriteAddress,
  AddFavoriteAddress,
  DeleteFavoriteAddress,
  GetUpsellGroup,
  getUpsellMenu,
  getAllMenus,
  FindRestaurant,
  CreateRestaurant,
  UpdateRestaurant,
  getUserDetails,
  UpdateUserDetails,
  AddFavoriteRestaurant,
  GetFavoriteRestaurant,
  UpdateFavoriteRestaurant,
  userSignIn,
  menuItemCreate,
  menuItemFind,
  menuItemUpdate,
  bannerImageFind,
};
